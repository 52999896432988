h1,h2,h3,h4,h5,h6{
    font-weight: bold;
    padding-top: 10px;
}
ul{
    list-style:circle;
    margin-left: 20px;
    margin-right: 20px;
}
ol{
    list-style:decimal;
    margin-left: 10px;
    margin-right: 10px;
}