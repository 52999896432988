@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --themColor:#EEEEEE;
  --secondaryColor:#430A5D;
  --darkColor:#76ABAE;
}

body {
  margin: 0;
  font-family: "Tajawal", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.active{
  color:#6466f1;
}



.primary-bg-color{
  background-color: var(--themColor)!important;
}
.primary-color{
  color: var(--themColor) !important;
}
.secondary-bg-color{
  background-color: var(--secondaryColor) !important;
}
.secondary-color{
  color: var(--secondaryColor) !important;
}
.menu-border-color{
  border-color: var(--darkColor);
}
/* Butons */
.primary-btn{
  border-width: 1px ;
  border-color: var(--themColor);
  color: var(--themColor);
}

.ce-block__content,
    .ce-toolbar__content {
        max-width: unset;
    }


    .header, .header-space {
      height: 215px;
    }
    .footer, .footer-space {
      height: 80px;
    }
    .header {
      position:absolute;
      top: 10px;
      padding-top: 120px;
      width: 90%;
    }
    .footer {
      position: fixed;
      bottom: 0;
      display: none;
    }
    
.page {
  /* padding: 20px; */
  /* /* page-break-before: always; */
  
}

@page {
  margin: 20px;
}

@media print {
  thead {display: table-header-group;} 
  tfoot {display: table-footer-group;}
  .footer{
    display: block;
  }
  print-button {display: none;}
  .page {
    /* padding: 20px; */
    page-break-before: always;
  }
  body {margin: 0;}
  .header {
    position: fixed;
    padding: 0px;
      width: 100%;
  }

}

body > iframe {
  display: none;
}